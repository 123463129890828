<template>
  <div class="home flex flexC">

    <div class="main-banner-layout">
      <div class="main-banner">
        <div class="main-banner-text">
          <span style="color: rgb(255, 255, 255);">码农互助智能门户平台</span>
          <span style="color: rgb(34, 177, 234);">专注互联网信息化服务</span>
          <p>网站定制开发/微信开发/小程序开发/APP开发/商城开发等</p>
        </div>
      </div>
    </div>

    <div class="flex bgGray p20 dingzhikaifa flex-align-items" style=" background-color: #2f3950;">
        <div class="flex1 maxwidth300">
          <div class="flex">
          <div class="flex1 tr">
            <img src="https://preview.aipage.com/resources/images/329c764652861590840066405.png" alt="" />
          </div>
          <div class="flex3 textWhite">
            <h3 class="f24 mb10 mt20">专注</h3>
            <span>团队始于2012年，专注互联网信息化服务超过6年时间</span>
          </div>
        </div>
        </div>
        <div class="flex1 maxwidth300">
          <div class="flex">
          <div class="flex1 tr">
            <img src="https://preview.aipage.com/resources/images/329c764652861590840453649.png" alt="" />
          </div>
          <div class="flex3 textWhite">
            <h3  class="f24 mb10 mt20">原创</h3>
            <span>拥有自主软件著作版权，坚持项目定制开发，服务用户</span>
          </div>
        </div>
        </div>
        <div class="flex1 maxwidth300">
          <div class="flex">
          <div class="flex1 tr">
            <img src="https://preview.aipage.com/resources/images/329c764652861590840577637.png" alt="" />
          </div>
          <div class="flex3 textWhite">
            <h3 class="f24 mb10 mt20">专业</h3>
            <span>团队成员平均从业经验超过8年，都是互联网"老司机"</span>
          </div>
        </div>
        </div>
    </div>

    <div class="mt1 pt20 tc bgGray">
      <p class="fb f24 mb20 pt20">服务领域</p>
      <div class="f12">根据业务场景，定制开发</div>
    </div>
    <div class="flex bgGray pl20 pb20 pr20 over-hidden">
      <div class="flex1 tc m20 bgWhite p20 br10 shadow5" >
            <img alt="定制开发，一对一服务" class="mt10" src="../assets/pic2_2.png">
            <p class="title m10">电商小程序</p>
            <div class="f12">用户系统<br />商品管理系统<br />订单流程<br />交付接口对接<br />物流跟踪API</div>
      </div>
      <div class="flex1 tc m20 bgWhite p20 br10 shadow5" >
            <img alt="定制开发，一对一服务" class="mt10" src="../assets/pic2_3.png">
            <p class="title m10">O2O小程序</p>
            <div class="f12">服务展示<br />门店管理<br />线上预约<br />会员系统<br />订单管理系统<br />地理信息对接</div>
      </div>
      <div class="flex1 tc m20 bgWhite p20 br10 shadow5"  >
            <img alt="定制开发，一对一服务" class="mt10" src="../assets/pic2_4.png">
            <p class="title m10">工具小程序</p>
            <div class="f12">群功能开发<br />表单收集系统<br />资讯服务<br />信息查询<br />消息通知推送</div>
      </div>
      <div class="flex1 tc m20 bgWhite p20 br10 shadow5" >
            <img alt="定制开发，一对一服务" class="mt10" src="../assets/pic2_5.png">
            <p class="title m10">系统小程序</p>
            <div class="f12">物料设备管理<br />供应链管理<br />CRM、OA、ERP...<br />系统功能对接</div>
      </div>
    </div>

    <div class="flex itemC rowC p20">
      <div class="tc flex1 transform" transform="left">
          <p class="f36 fb m10">商城小程序、微商城、PC商城</p>
          <div class="f18 mt20 pt10">多种营销形式、强裂变、高复购、助力商城高收益</div>
      </div>
      <div class="tc flex1">
        <img alt="定制开发，一对一服务" class="m20" width="450" src="../assets/pic2_7.png">
      </div>
    </div>

    <div class="flex bgBlue itemC rowC p20 over-hidden" id="test-001">
      <div class="tc flex1">
        <img alt="定制开发，一对一服务" class="m20" width="450" src="../assets/pic2_6.png">
      </div>
      <div class="tc flex1 transform" transform="right">
          <p class="f36 fb m10">使用小程序 抢占流量红利</p>
          <div class="f18 mt20 pt10">共享10亿微信用户，分享快</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Wx',
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', () => {
        const transformList = document.querySelectorAll('.transform[transform]');
        transformList.forEach(item => {
          if (this.isInViewPort(item)) {
            item.hasAttribute('started') && item.removeAttribute('started')
          } else {
            !item.hasAttribute('started') && item.setAttribute('started', true)
          }
        })
      })
    })
  },
  methods: {
    isInViewPort(el) {
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = el.offsetTop;
      const scollTop = document.documentElement.scrollTop;
      return offsetTop - scollTop <= viewPortHeight - 100
    }
  },
  components: {
  },

}
</script>
<style type="text/css">
@keyframes uk-scale-kenburns {
    0% {
      /* transform: scale(1) */
      background-size: 106%;
    }
    100% {
      /* transform: scale(1.2) */
      background-size: 140%;
    }
}
.main-banner-layout{
  height: 390px;
  width: 100%;
  overflow: hidden;
}
.main-banner {
  height: 390px;
  background-image: url("../assets/banner.png");
  background-repeat: no-repeat;
  background-position: center center;
  animation-name: uk-scale-kenburns;
  background-size: 106%;
  animation-duration: 15s;
  transform-origin: 0 50%;
  animation-direction: reverse;
  animation-timing-function: ease-in;
  overflow: hidden;
}

.main-banner-text{
  text-align: center;
  margin: 90px auto 0;
}

.main-banner-text span {
  font-size: 42px;
  line-height: 120px;
  font-weight: 800;
  margin: 0 10px;
}

.main-banner-text p {
  font-size: 24px;
  line-height: 40px;
  color: #fff;
}

</style>
